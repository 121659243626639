/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";

import Test from "../other/test";
import StaticContent from "../components/AC-StaticPages/StaticContent";

const sellerRegister = () => {
  return (
    <Layout>
      <SEO title="Seller Registration" />
      <StaticContent />
    </Layout>
  );
};

export default sellerRegister;
